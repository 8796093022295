import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import * as yup from "yup";

import Typography from "@mui/material/Typography";
import CustomForm from "./CustomForm";
import client from "../../apis/client";
import { LocalizationContext } from "../../context/LocalizationContext";

const initialValues = {
  new_password: "",
  re_new_password: "",
};

const NewPasswordForm = () => {
  const { uid, token } = useParams();
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState("");
  const { t } = React.useContext(LocalizationContext);

  const resetPassword = async (values) => {
    try {
      setDisabled(true);
      const new_password = values.new_password;
      const re_new_password = values.re_new_password;

      if (new_password === re_new_password) {
        const res = await client.post(`/auth/users/reset_password_confirm/`, {
          uid: uid,
          token: token,
          new_password: new_password,
        });

        if (res.status === 204) {
          setSuccess("Uspesno promenjena lozinka");
          setDisabled(false);
        }
      }
    } catch (err) {
      setDisabled(false);
      if (err.response) {
        const values = Object.values(err.response.data);
        const keys = Object.keys(err.response.data);

        if (
          values[0][0] ===
            "This password is too short. It must contain at least 8 characters." &&
          keys[0] === "new_password"
        ) {
          setError(t("tooShort"));
          return;
        } else if (
          values[0][0] === "This field may not be blank." &&
          keys[0] === "new_password"
        ) {
          setError(t("allFields"));
          return;
        } else if (
          values[0][0] === "This password is too common." &&
          keys[0] === "new_password"
        ) {
          setError(t("tooCommon"));
          return;
        } else if (
          values[0][0] === "The password is too similar to the username." &&
          keys[0] === "new_password"
        ) {
          setError(t("tooSimilar"));
          return;
        }
        setError(JSON.stringify(err.response.data));
      }
    }
  };

  const fields = [
    {
      name: "new_password",
      label: t("newPassword"),
      type: "password",
    },
    {
      name: "re_new_password",
      label: t("reNewPassword"),
      type: "password",
    },
  ];

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .min(8, t("minChar"))
      .max(40, t("maxChar"))
      .required(t("required")),
    re_new_password: yup
      .string()
      .required(t("required"))
      .oneOf([yup.ref("new_password"), ""], t("matchPassword")),
  });

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onFormSubmit={resetPassword}
          disabled={disabled}
        />
      )}
      {success !== "" && (
        <Typography variant="subtitle1" color={"success"} paddingTop={2}>
          {success}
        </Typography>
      )}

      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default NewPasswordForm;
