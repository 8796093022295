import React, { useCallback } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Box } from "@mui/material";
import { colors } from "../../infrastructure/resources/colors";
import Spacer from "../../infrastructure/components/Spacer";
import { LocalizationContext } from "../../context/LocalizationContext";
import MultiForOpenTest from "../../infrastructure/components/multicheck/MultiForOpenTest";

const OpenTest = ({ test }) => {
  const { t } = React.useContext(LocalizationContext);
  const [multiChoiceArray, setMultiChoiceArray] = React.useState([]);
  const [showResults, setShowResults] = React.useState(false);
  const [userPoints, setUserPoints] = React.useState(0);
  const [totalPoints, setTotalPoints] = React.useState(0);

  const multiChoiceHandler = useCallback(
    (questionId, selectedChoices) => {
      setMultiChoiceArray((prev) => [
        ...prev.filter((c) => c.questionId !== questionId),
        { questionId, selectedChoices },
      ]);
    },
    [setMultiChoiceArray]
  );

  const findTestResult = () => {
    setShowResults(true);

    if (test && test.questions) {
      let points = 0;
      let total = 0;

      test.questions.forEach((q) => {
        const selectedChoices = multiChoiceArray.find(
          (mc) => mc.questionId === q.id
        );
        total+=q.points
        if (selectedChoices) {
          const correctChoices = q.choices
            .filter((choice) => choice.is_correct)
            .map((choice) => choice.id);

          const isCorrect =
            JSON.stringify(correctChoices.sort()) ===
            JSON.stringify(selectedChoices.selectedChoices.sort());

          if (isCorrect) {
            points += q.points;
          }
        }
      });

      setUserPoints(points);
      setTotalPoints(total);
    }
  };

  return (
    test && (
      <Container component="main" maxWidth="md">
        <Spacer height={"5rem"} />
        <Typography sx={{ textAlign: "center" }} variant="h4">
          {test.name}
        </Typography>
        <Spacer height={"1rem"} />
        {test?.questions && (
          <form>
            <ol>
              {test.questions.map((q) => (
                <li key={q.id} value={q.value} style={{ paddingTop: "3rem" }}>
                  {q.text}
                  {q.image !== null && (
                    <Box
                      component="img"
                      sx={{
                        paddingTop: "0.8rem",
                        display: "block",
                        width: 650,
                        maxWidth: { xs: 350, md: 550 },
                      }}
                      src={q.image}
                    />
                  )}

                  <MultiForOpenTest
                    questionId={q.id}
                    questionText={q.choice}
                    answersArray={q.choices}
                    onInputChange={multiChoiceHandler}
                    correctChoices={q.choices
                      .filter((choice) => choice.is_correct)
                      .map((choice) => choice.id)}
                    showResults={showResults}
                    points={q.points}
                  />
                </li>
              ))}{" "}
            </ol>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "2rem",
              }}
            >
              {showResults && (
                <Typography variant="h6">
                  {t("sumPoints")} {userPoints}/{totalPoints}
                </Typography>
              )}
              {!showResults && <Button
                color="primary"
                variant="contained"
                onClick={findTestResult}
                style={{ backgroundColor: colors.green, width: "40%" }}
              >
                {t("endTest")}
              </Button>}
            </div>
          </form>
        )}
        <Spacer height={"2rem"} />
      </Container>
    )
  );
};

export default OpenTest;
