import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import I18n from "i18n-js";

import { LocalizationContext } from "./context/LocalizationContext";
import { Provider as AuthProvider } from "./context/AuthContext";

import AppStore from "./pages/AppStore";
import HomePage from "./pages/HomePage";
import SignInPage from "./pages/auth/SignInPage";
import SignUpPage from "./pages/auth/SignUpPage";

import Page404 from "./pages/Page404";
import Navigator from "./infrastructure/components/Navigator";
import TestPage from "./pages/TestPage";

import VideotekaPage from "./pages/VideotekaPage";
import LanguageDropdown from "./infrastructure/components/ui-components/LangugageDropdown";
import OpenTestsPage from "./pages/OpenTestsPage";
import CoursePage from "./pages/CoursePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";

const App = () => {
  const [locale, setLocale] = React.useState("cyr");
  var isActive = useRef(true);

  const localizationContext = React.useMemo(
    () => ({
      t: (scope, options) => I18n.t(scope, { locale, ...options }),
      locale,
      setLocale,
    }),
    [locale]
  );

  React.useEffect(() => {
    let language = localStorage.getItem("language");
    if (isActive.current === true) setLocale(language || "cyr");
    return () => {
      isActive.current = false;
    };
  }, []);

  let routes;

  if (localStorage.getItem("refreshToken") === null) {
    routes = (
      <>
        <LanguageDropdown />
        <Routes>
          <Route path="/" element={<SignInPage />} />
          <Route path="/register" element={<SignUpPage />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/password" element={<ForgotPasswordPage />} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<NewPasswordPage />}
          />
        </Routes>
      </>
    );
  } else {
    routes = (
      <>
        <div>{window.location.pathname === "/test" ? null : <Navigator />}</div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/videoteka" element={<VideotekaPage />} />
          <Route path="/videoteka/:id" element={<CoursePage />} />
          {/* <Route path="/testovi" element={<OpenTestsPage />} /> */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </>
    );
  }

  return (
    <div>
      <Router>
        <LocalizationContext.Provider value={localizationContext}>
          <AuthProvider>
            <AppStore>
              <main>{routes}</main>
            </AppStore>
          </AuthProvider>
        </LocalizationContext.Provider>
      </Router>
    </div>
  );
};

export default App;
