import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { Formik, useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Spacer from "../../infrastructure/components/Spacer";
import client_auth from "../../apis/client_auth";

import { colors } from "../../infrastructure/resources/colors";
import { Link, Stack, Typography } from "@mui/material";
import { logo2 } from "../../infrastructure/resources/images";
import "../logo.css";
import { LocalizationContext } from "../../context/LocalizationContext";
import ForgotPasswordPage from "../ForgotPasswordPage";
import CustomTextField from "../../infrastructure/components/CustomTextField";

const SignInPage = () => {
  const { t } = React.useContext(LocalizationContext);
  const navigate = useNavigate();

  const [error, setError] = React.useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const redirectForgotPassword = () => {
    navigate("/password");
    navigate(0);
  };

  const initialValues = {
    email: localStorage.getItem("email") ?? "",
    password: localStorage.getItem("password") ?? "",
    rememberMe: false,
  };
  const onSignIn = async (values) => {
    try {
      const loginData = {
        email: values.email,
        password: values.password,
      };

      const response = await client_auth.post("/auth/jwt/create/", loginData);

      const tok = JSON.stringify(response.data);
      const parsedData = JSON.parse(tok);

      localStorage.setItem("token", parsedData.access);
      localStorage.setItem("refreshToken", parsedData.refresh);

      if (values.rememberMe === true) {
        localStorage.setItem("email", values.email);
        localStorage.setItem("password", values.password);
      }

      navigate("/");
      navigate(0);
    } catch (error) {
      let err = Object.values(error.response.data);
      console.log(err);
      if (err[0] === "No active account found with the given credentials") {
        setError(t("noActiveAccount"));
        return;
      } else {
        setError(t("checkData"));
        return;
      }
    }
  };

  const validationSchema = yup.object({
    email: yup.string().email(t("invalidEmail")).required(t("required")),
    password: yup.string().required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSignIn,
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={logo2.src} alt="mihailo" className="custom-image" />
      </div>
      <Container component="main" maxWidth="sm" className="centered-container">
        <Spacer height={"1rem"} />
        <Typography variant="h4" align="center">
          {t("login")}
        </Typography>
        <Spacer height={"2rem"} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" sx={{ lineHeight: 1 }}>
            {t("dontHaveAcc")}
          </Typography>
          <Link
            href="/register"
            underline="none"
            style={{ marginLeft: "3px", lineHeight: 1, color: colors.green }}
          >
            {t("goRegister")}
          </Link>
        </div>

        <Spacer height={"2rem"} />
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2} flexDirection="">
            <CustomTextField
              fullWidth
              id="email"
              name="email"
              variant="outlined"
              label={t("email")}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <CustomTextField
              fullWidth
              id="password"
              label={t("password")}
              name="password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              name="rememberMe"
              control={
                <Checkbox
                  value={formik.values.rememberMe}
                  onChange={formik.handleChange}
                />
              }
              label={t("rememberMe")}
            />
            {error !== "" && (
              <Typography sx={{ color: "red" }}>{error}</Typography>
            )}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              style={{ backgroundColor: colors.green }}
            >
              {t("login")}
            </Button>

            <Typography
              variant="h6"
              textAlign="center"
              onClick={redirectForgotPassword}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: colors.green,
              }}
            >
              {t("forgotPasswordForm")}
            </Typography>
          </Stack>
        </form>
      </Container>
    </>
  );
};

export default SignInPage;
