import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { LocalizationContext } from "../context/LocalizationContext";

import client from "../apis/client";
import { colors } from "../infrastructure/resources/colors";
import VideoCard from "../infrastructure/components/VideoCard";
import CourseCard from "../infrastructure/components/CourseCard";

const CoursePage = (props) => {
  const { t } = React.useContext(LocalizationContext);
  const { id } = useParams();
  const [course, setCourse] = useState();
  const [lection, setLection] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showTest, setShowTest] = useState(false);
  const [testData, setTestData] = useState(false);

  const handleSelectLection = async (id) => {
    try {
      const lec = await client.get(`/education/lectures/${id}`);
      if (lec.status === 200) {
        setLection(await lec.data);
        setShowTest(false)
        setTestData(false)
      }
    } catch (err) {}
  };

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      try {
        const vid = await client.get(`/education/courses/${id}`);

        if (isActive) {
          if (vid.status === 200) {
            setCourse(vid.data);
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    };

    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, []);

  const styles = {
    heightStyle: {
      marginTop: "2rem",
      maxWidth: { sm: "100%", md: "70%" },
      width: {
        sm: "100vw",
        md: "70vw",
      },
      height: {
        sm: "30vh",
        md: "80vh",
      },
      overflowY: "auto",
    },
    topStyle: {
      height: 300,
      overflowY: "auto",
    },
    lectionStyle: {
      width: "100%",
      height: "100%",
      overflowY: "auto",
      alignItems: "top",
    },
    noneText: { paddingTop: "5rem", textAlign: "center" },
  };

  return (
    <Grid container alignItems={"center"}>
      {!lection &&
        course &&
        course?.lectures &&
        course?.lectures.length > 0 && (
          <Grid item sx={styles.heightStyle} order={{ xs: 2, md: 1 }}>
            <Typography variant="h5" paddingTop="2rem" textAlign={"center"}>
              {t("chooseVideo")}
            </Typography>
          </Grid>
        )}

      {!lection &&
        course &&
        course?.lectures &&
        course?.lectures.length === 0 && (
          <Grid item sx={styles.heightStyle} order={{ xs: 2, md: 1 }}>
            <Typography variant="h5" paddingTop="2rem" textAlign={"center"}>
              {t("noVideosYet")}
            </Typography>
          </Grid>
        )}

      {lection && (
        <Grid item sx={styles.heightStyle} order={{ xs: 2, md: 1 }}>
          <VideoCard
            video={lection}
            setShowTest={setShowTest}
            showTest={showTest}
            testData={testData}
            setTestData={setTestData}
          />
        </Grid>
      )}

      {course && (
        <Grid
          item
          zeroMinWidth
          sx={{
            ...styles.heightStyle,
            maxWidth: { sm: "100%", md: "30%" },
            order: { xs: 1, md: 2 },
          }}
        >
          <CourseCard course={course} />
          {course?.lectures && course?.lectures.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "50vh",
                overflowY: "auto",
              }}
            >
              {course?.lectures.map((category, idx) => (
                <div
                  key={idx}
                  style={{
                    width: "60%",
                    backgroundColor:
                      selectedVideo && selectedVideo.id === category.id
                        ? colors.green
                        : "transparent",
                    padding: "1rem",
                    borderRadius: "15px 15px",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    onClick={() => {
                      handleSelectLection(category.id);
                      setSelectedVideo(category);
                    }}
                    dangerouslySetInnerHTML={{ __html: category.title }}
                    sx={{
                      textAlign: "left",
                      whiteSpace: "pre-line",

                      color:
                        selectedVideo && selectedVideo.id === category.id
                          ? colors.white
                          : colors.black,
                    }}
                  />
                  <div style={{ borderBottom: `1px solid ${colors.green}` }} />
                </div>
              ))}
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CoursePage;
