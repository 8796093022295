import React from "react";

import Stack from "@mui/material/Stack";

import { colors } from "../../resources/colors";
import CustomButton from '../CustomButton';

const LanguageDropdown = ({ fontSize }) => {
  console.disableYellowWarnin = true;
  const [locale, setLocale] = React.useState(localStorage.getItem("language"));

  const handleSetLocale = (value) => {
    setLocale(value);
    localStorage.setItem("language", value);
    window.location.reload();
  };

  return (
    <>
      <Stack spacing={0} direction="row" justifyContent={"flex-end"}>
        <CustomButton
          text={"ћир"}
          value={"cyr"}
          handleClick={(event) => handleSetLocale(event.target.value)}
          width={"max-content"}
          background={colors.white}
          color={colors.green}
        />
        <CustomButton
          text={"lat"}
          width={"max-content"}
          value={"sr"}
          handleClick={(event) => handleSetLocale(event.target.value)}
          background={colors.white}
          color={colors.green}
        />
        <CustomButton
          text={"eng"}
          value={"en"}
          handleClick={(event) => handleSetLocale(event.target.value)}
          background={colors.white}
          color={colors.green}
          width={"max-content"}

        />
      </Stack>
    </>
  );
};

export default LanguageDropdown;
