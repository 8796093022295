import React, { useState, useEffect } from 'react'

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import Spacer from '../Spacer';
import './MultiChoiceAnswer.css'


const MultiChoiceAnswer = (props) => {
    const [data] = React.useState(props.answersArray.map(item => ({ answer_id: item.id, answer_text: item.choice, checked: false })));
    const [selectedData] = useState('');
    const [item, setItem] = useState({});

    const { onInputChange } = props;

    useEffect(() => {
        onInputChange(item, selectedData);

    }, [item, selectedData, onInputChange]);

    function selectItem(key, questionId) {
        let gr = [...data];
        for (let item of gr) {
            if (item.answer_id === key) {
                item.checked = (item.checked === null) ? true : !item.checked;
                break;
            }
        }

        const newArray = gr.filter(g => (g.checked === true));
        let helpString = '';

        if (newArray.length === 0) {
            setItem('');
        } else {
            newArray.map((a) => {
                helpString = helpString.concat(a.answer_id).concat('$')
                setItem({ 'choice_answer': { 'choices': helpString.slice(0, -1) }, 'question': questionId });

            })
        }
    }


    return (
        <>
            <Spacer height={'0.5rem'} />
            <FormGroup>

                {data.map(a => {
                    return (
                        <div key={a.answer_id}>
                            <FormControlLabel control={<Checkbox checked={a.checked}
                                onChange={() => selectItem(a.answer_id, props.questionId)} />} label={a.answer_text} />
                        </div>)
                })}
            </FormGroup>
        </>
    )
}

export default MultiChoiceAnswer

