import React, { useContext, useEffect, useState } from "react";

import { Button, Grid, MenuItem } from "@mui/material";
import { LocalizationContext } from "../../context/LocalizationContext";
import client from "../../apis/client";
import CustomTextField from "./CustomTextField";
import Spinner from "./Spinner";
import { colors } from "../resources/colors";

const boxStyle = {
  borderRadius: "20px 20px",
  boxShadow: `0 0 20px 1px grey`,
  padding: 3,
  marginLeft: "0px",
};

const CourseFilterForm = (props) => {
  const { t } = useContext(LocalizationContext);
  const [labels, setLabels] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const noFilter = {
    id: "id",
    name: "-------",
  };

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      setIsLoading(true);
      const lab = await client.get("/education/labels/");
      if (isActive) {
        setLabels([noFilter, ...lab.data]);
      }
      setIsLoading(false);
    };
    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <form
      onSubmit={props.formik.handleSubmit}
      onReset={() => {
        props.formik.handleReset();
      }}
    >
      {isLoading && (
        <Grid container spacing={1} sx={{ ...boxStyle, height: "20vh" }}>
          <Spinner />
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={1} sx={boxStyle}>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            {labels !== undefined && labels.length !== 0 && (
              <CustomTextField
                id="label"
                select
                label={t("label")}
                name="label"
                fullWidth
                variant="outlined"
                value={props.formik?.values.label}
                onChange={props.formik?.handleChange}
              >
                {labels !== undefined &&
                  labels.map((option, idx) => (
                    <MenuItem key={idx} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              type="reset"
              variant="contained"
              style={{
                backgroundColor: colors.white,
                color: colors.green,
              }}
            >
              {t("resetFilter")}
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ backgroundColor: colors.green, marginLeft: "10px" }}
            >
              {t("submitFilter")}
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default CourseFilterForm;
