import React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Spacer from '../infrastructure/components/Spacer';
import { LocalizationContext } from '../context/LocalizationContext';

const Page404 = () => {
    const { t } = React.useContext(LocalizationContext);

    return (
        <>
            <Spacer height={'15rem'} />
            <Container component='main' maxWidth='md'>
                <Typography component='h1' variant='h2' sx={{ textAlign: 'center' }}>
                    {t('notFound')}
                </Typography>
            </Container>

        </>
    );
}

export default Page404;