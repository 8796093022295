//method to shuffle elements in array

export const shuffle = (array = []) => {
    let randIndex;
    for (let currentIndex = array.length - 1; currentIndex > 0; currentIndex--) {
        randIndex = Math.floor(Math.random() * (currentIndex + 1));
        [array[currentIndex], array[randIndex]] = [
            array[randIndex],
            array[currentIndex]
        ];
    }
    return array;
};