import React, { useState } from "react";
import client from "../../apis/client";

import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  CardMedia,
  Link,
  Grid,
} from "@mui/material";
import { LocalizationContext } from "../../context/LocalizationContext";
import { colors } from "../resources/colors";
import CustomButton from "./CustomButton";
import ReactPlayer from "react-player";
import OpenTest from '../../pages/forms/OpenTest';

const VideoCard = ({ video, showTest, setShowTest, testData, setTestData }) => {
  const { t } = React.useContext(LocalizationContext);

  const onTestClick = async (id) => {
    try {
      const t = await client.get(`/education/assessments/${id}/`);
      if (t.status === 200) {
        setTestData(t.data);
        setShowTest(true);
      }
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {video && !showTest && (
        <Card sx={{border: "none", boxShadow: "none"}}>
          <CardHeader
            title={
              <Grid container spacing={2} direction={"row"} sx={{marginBottom: "1rem"}}>
                {video.assessments &&
                  video.assessments.length > 0 &&
                  video.assessments.map((test, idx) => (
                    <Grid
                      key={idx}
                      item
                      xs={9 / video.assessments.length}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <CustomButton
                        background={colors.blue}
                        handleClick={() => onTestClick(test.id)}
                        text={
                          <Link underline="none" sx={{ color: colors.white }}>
                            {test.name}
                          </Link>
                        }
                        width={"fit-content"}
                      />
                    </Grid>
                  ))}
                {video.file && (
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CustomButton
                      text={
                        <Link
                          href={video.file}
                          download
                          underline="none"
                          sx={{ color: colors.white }}
                        >
                          {t("downloadFile")}
                        </Link>
                      }
                      width={"fit-content"}
                    />
                  </Grid>
                )}
              </Grid>
            }
            subheader={
              <Grid container>
                {video?.video && (
                  <ReactPlayer
                    url={video.video}
                    controls
                    width="100%"
                    height="auto"
                  />
                )}
                {!video?.video && video?.video_url && (
                  <ReactPlayer
                    url={video?.video_url}
                    controls
                    width="100%"
                    height="auto"
                    style={{minHeight: "565px"}}
                  />
                )}
              </Grid>
            }
          />

          <CardContent>
            <Typography variant="h6" dangerouslySetInnerHTML={{ __html: video.title }} />
            <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
              {video.text}
            </Typography>
            {video?.video && video?.video_url && (
              <Typography variant="body1" sx={{ marginTop: "2rem" }}>
                {t("seeMore")}{" "}
                <Link href={video.video_url}>{t("nextLink")}</Link>
              </Typography>
            )}
            {video.image && (
              <CardMedia component="img" width="60%" image={video.image} />
            )}
          </CardContent>
        </Card>
      )}
      {!video && (
        <Typography
          variant="h4"
          sx={{ textAlign: "center", paddingTop: "2rem" }}
        >
          {t("chooseVideo")}
        </Typography>
      )}
      {video && showTest && testData && (
        <OpenTest test={testData} />
      )}
    </React.Fragment>
  );
};

export default VideoCard;
