import React, { useContext } from "react";

import { Container, Typography } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { LocalizationContext } from "../context/LocalizationContext";
import ForgotPassword from "./forms/ForgotPasswordForm";
import { logo2 } from "../infrastructure/resources/images";
import "./logo.css";
import Spacer from "../infrastructure/components/Spacer";
import { colors } from "../infrastructure/resources/colors";

const ForgotPasswordPage = () => {
  const { t } = React.useContext(LocalizationContext);

  const navigate = useNavigate();

  const redirectLogin = () => {
    navigate("/");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={logo2.src} alt="mihailo" className="custom-image" />
      </div>
      <Container component="main" maxWidth="xs">
        <Spacer height={"0.1rem"} />
        <Typography variant="h4" align="center">
          {t("forgotPasswordForm")}
        </Typography>
        <Spacer height={"2rem"} />
        <Typography variant="h6" textAlign="center" paddingBottom="7%">
          {t("enterEmail")}
        </Typography>
        <ForgotPassword />
        <Link
          to="/"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: colors.green,
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ color: colors.green }}
            paddingTop="5%"
            onClick={redirectLogin}
          >
            {t("cancel")}
          </Typography>
        </Link>
      </Container>
    </>
  );
};
export default ForgotPasswordPage;
