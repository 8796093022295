const eng = {
  home: "Home",
  profile: "Profile",
  education: "Education",
  tests: "Tests",
  videoLibrary: "Video library",
  logout: "Logout",
  login: "Login",
  register: "Register",
  email: "Email",
  password: "Password",
  rememberMe: "Remember me",
  submit: "Submit",
  submitLogin: "Submit",
  submitRegister: "Submit",
  submitForgot: "Submit",
  submitResetPassword: "Submit",
  submitProfile: "Submit",
  submitChangePassword: "Submit",
  submitDeleteAccount: "Submit",
  required: "Required",
  invalidEmail: "Invalid email",
  rePassword: "Repeat password",
  tooSimilar: "The password is too similar to the email.",
  tooCommon: "This password is too common",
  alreadyExist: "A user with that email already exists.",
  passwordSimilarEmail: "The password is too similar to the email",
  checkData: "Make sure you have entered the data correctly",
  min8: "Password must be at least 8 characters",
  max40: "Password must not exceed 40 characters",
  downloadFile: "Download file",
  noMoreVideo: "No more video lections",
  succesRegister: "You successfully registered",
  noActiveAccount: "No active account found with the given credentials",
  forgotPassword: "Forgot password",
  dontHaveAccount: "If you don't have an account yet, please ",
  registerPage: "go to the register page.",
  succesForgot:
    "We have e-mailed your password reset link! If you do not receive the email within a few minutes, please check your spam folder or promotions.",
  goToStart: "Go to the Start Page",
  name: "Name",
  comingSoon: "Coming soon",
  rePassword: "Repeat password",
  forgotPasswordForm: "Forgot password?",
  enterEmail:
    "Enter your email and we will send you a link to reset your password.",

  registerMessage: "Dont have an account? ",
  loginMessage: "Already have an account? ",
  enterValidEmail: "Enter valid email",
  language: "Language",
  sr: "Serbian",
  en: "English",
  byRegister: "By registering, I accept ",
  termsAndCondition: "Terms and Condition.",
  termsAndConditionModal: "Terms and Condition",
  haveAccount: "If you have an account, please ",
  loginPage: "go to the login page",
  currentPassword: "Current password",
  newPassword: "New password",
  reNewPassword: "Repeat new password",
  minChar: "Must be at least 8 characters",
  maxChar: "Must be maximum 40 characters",
  matchPassword: "Passwords didn't match",
  successChangePass: "Your password has been changed successfully.",
  createAvatar: "Add image",
  male: "Male",
  female: "Female",
  other: "Other",
  dateOfBirth: "Date of birth",
  gender: "Gender",
  city: "City",
  succesProfileCreate: "You succesfully create profile!",
  succesProfileEdit: "You succesfully edit profile!",
  successTestComplete: "You succesfully completed the test",
  endTest: "End test",
  invalidPassword: "Invalid password",
  profileEdit: "Edit profile",
  profileCreate: "Create profile",
  startTest: "Start test",
  startVideoTest: "Start video test",
  editProfile: "Edit profile",
  changePassword: "Change password",
  deleteAccount: "Delete account",
  invalidCurrentPassword: "Invalid current password",
  successDeleteAccount: "You successfully delete your account.",
  noTest: "Sorry, you don't have test.",
  notFound: "Page not found.",
  checkDate: "Check entires",
  cancel: "Cancel",
  tooShort: "Too short",
  passwordShort: "This password is short",
  allFields: "All fields",
  createNewPassword: "Create new password",
  smallDate: "End date can't be smaller than a start date",
  serverError: "Server error",
  error: "Error",
  dontHaveAcc: "Don't have account?",
  goRegister: "Sign Up.",
  goLogin: "Sign In.",
  noVideosYet: "No videos in this section",
  chooseVideo: "Choose video",
  seeMore: "See video on ",
  nextLink: "next link.",
  label: "Type of course",
  submitFilter: "Submit",
  resetFilter: "Reset",
  sumPoints: "Achieved points",
};

export default eng;
