import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { LocalizationContext } from "../../context/LocalizationContext";
import { colors } from "../../infrastructure/resources/colors";
import Spacer from "../../infrastructure/components/Spacer";
import client_auth from "../../apis/client_auth";
import { logo2 } from "../../infrastructure/resources/images";
import "../logo.css";
import { Typography } from "@mui/material";
import CustomTextField from "../../infrastructure/components/CustomTextField";

const initialValues = {
  name: "",
  username: "",
  email: "",
  password: "",
  re_password: "",
};

const SignUpPage = () => {
  const { t } = React.useContext(LocalizationContext);
  const navigate = useNavigate();

  const [error, setError] = React.useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowRePassword = () => setShowRePassword(!showRePassword);
  const handleMouseDownRePassword = () => setShowRePassword(!showRePassword);

  const onLogin = async () => {
    try {
      navigate("/");
      navigate(0);
    } catch (error) {}
  };

  const onSignUp = async (values) => {
    try {
      const newUser = {
        email: values.email,
        password: values.password,
        re_password: values.re_password,
      };

      const reg = await client_auth.post(`/auth/users/`, newUser);

      if (reg.status === 201) {
        alert(t("succesRegister"));
        navigate("/");
        navigate(0);
      }
    } catch (error) {
      if (error.response) {
        const values = Object.values(error.response.data);
        const keys = Object.keys(error.response.data);

        if (keys !== undefined && values !== undefined) {
          if (keys.length !== 0 && values.length !== 0) {
            if (
              (values[0][0] === "user with this email already exists." &&
                keys[0] === "email") ||
              (values[0][0] === "A user with that email already exists." &&
                keys[0] === "email")
            ) {
              setError(t("alreadyExist"));
            } else if (
              (values[0][0] ===
                "This password is too short. It must contain at least 8 characters." ||
                values[0][0] === "This password is too common." ||
                values[0][0] === "This password is entirely numeric.") &&
              keys[0] === "password"
            ) {
              setError(t("passwordShort"));
            } else if (
              values[0][0] === "The password is too similar to the email." &&
              keys[0] === "password"
            ) {
              setError(t("passwordSimilarEmail"));
            } else if (
              values[0][0] === "Enter a valid email address." &&
              keys[0] === "email"
            ) {
              setError(t("enterValidEmail"));
            } else if (
              values[0][0] === "This password is too common." &&
              keys[0] === "password"
            ) {
              setError(t("tooCommon"));
            } else {
              setError(t("checkData"));
            }
          }
        }
      }
    }
  };

  const validationSchema = yup.object({
    email: yup.string().email(t("invalidEmail")).required(t("required")),
    password: yup
      .string()
      .required(t("required"))
      .min(8, t("min8"))
      .max(40, t("max40")),
    re_password: yup
      .string()
      .required(t("required"))
      .oneOf([yup.ref("password"), null], t("matchPassword")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSignUp,
  });

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={logo2.src} alt="mihailo" className="custom-image" />
      </div>

      <Container component="main" maxWidth="sm">
        <form onSubmit={formik.handleSubmit} className="mt-3 m-2">
          <Typography variant="h4" align="center">
            {t("register")}
          </Typography>
          <Spacer height={"2rem"} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="body1" sx={{ lineHeight: 1 }}>
              {t("loginMessage")}
            </Typography>
            <Link
              href="/"
              underline="none"
              style={{ marginLeft: "3px", lineHeight: 1, color: colors.green }}
            >
              {t("goLogin")}
            </Link>
          </div>

          <Spacer height={"2rem"} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="email"
                name="email"
                variant="outlined"
                label={t("email")}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="password"
                label={t("password")}
                name="password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="re_password"
                label={t("rePassword")}
                name="re_password"
                variant="outlined"
                type={showRePassword ? "text" : "password"}
                value={formik.values.re_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.re_password &&
                  Boolean(formik.errors.re_password)
                }
                helperText={
                  formik.touched.re_password && formik.errors.re_password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRePassword}
                        onMouseDown={handleMouseDownRePassword}
                      >
                        {showRePassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {error !== "" && (
              <Grid item xs={12}>
                <p style={{ color: "red" }}>{error}</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <Spacer height="2rem"></Spacer>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={{ backgroundColor: colors.green }}
              >
                {t("register")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default SignUpPage;
