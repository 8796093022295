import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { LocalizationContext } from "../context/LocalizationContext";

import client from "../apis/client";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { colors } from "../infrastructure/resources/colors";
import VideoCard from "../infrastructure/components/VideoCard";
import LabelsCard from "../infrastructure/components/LabelsCard";
import CourseFilterForm from "../infrastructure/components/CourseFilterForm";
import { useFormik } from "formik";

const VideotekaPage = (props) => {
  const { t } = React.useContext(LocalizationContext);
  const location = useLocation();
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    label: "",
  };

  const applyFilter = async (values) => {
    try {
      setIsLoading(true);
      let str = "";
      if (values.label !== "" && values.label !== "id")
        str += `labels=${values.label}&`;

      let fil = await client.get(`/education/courses/?${str.slice(0, -1)}`);

      if (fil.status === 200) {
        setVideos(fil.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleResetForm = async () => {
    try {
      const vid = await client.get(`/education/courses/`);

      if (vid.status === 200) {
        setVideos(vid.data);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: applyFilter,
    onReset: handleResetForm,
  });

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      try {
        const vid = await client.get(`/education/courses/`);

        if (isActive) {
          if (vid.status === 200) {
            setVideos(vid.data);
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    };

    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <CourseFilterForm formik={formik} />
      <div style={{ height: "3rem" }} />
      {videos && <LabelsCard labels={videos} />}
    </Container>
  );
};

export default VideotekaPage;
