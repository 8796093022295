import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import client from "../apis/client";
import Spacer from "../infrastructure/components/Spacer";

import MultiChoiceAnswer from "../infrastructure/components/multicheck/MultiChoiceAnswer";
import { shuffle } from "../infrastructure/resources/shuffle";
import Timer from "../infrastructure/components/Timer";
import { Box } from "@mui/material";
import { colors } from "../infrastructure/resources/colors";
import { LocalizationContext } from "../context/LocalizationContext";

const TestPage = (props) => {
  const { t } = React.useContext(LocalizationContext);

  const [questions, setQuestions] = useState();
  const [examData, setExamData] = useState();
  const [time, setTime] = useState();
  const [assessmentId, setAssessmentID] = useState("");
  const [buttonState, setButtonState] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      const as = await client.get(`/assessment/`);

      if (isActive) {
        if (as.data.length === 0) {
          navigate("/");
          navigate(0);
        } else {
          const exam = await client.get(
            `/assessment/${await as.data[as.data.length - 1].id}/`
          );

          setQuestions(shuffle(await exam.data.questions));
          setTime(await exam.data.minutes);
          setExamData(await exam.data);
          setAssessmentID(await exam.data.id);
        }
      }
    };

    if (isActive) {
      loadData();
    }

    return () => {
      isActive = false;
    };
  }, []);

  const [multiChoiceArray, setMultiChoiceArray] = React.useState([]);

  const multiChoiceHandler = useCallback(
    (item, mcArray) => {
      if (Object.keys(item).length !== 0) {
        setMultiChoiceArray((prev) => [
          ...prev.filter((c) => c.question !== item.question),
          item,
        ]);
      }
    },
    [setMultiChoiceArray]
  );

  const array = [...multiChoiceArray];

  const sendTest = async () => {
    try {
      setButtonState(true);

      const test = {
        answers: array,
        time_of_test: moment(new Date()).format("YYYY-MM-DDThh:mm"),
        assessment: assessmentId,
        profile: location.state.profile,
      };

      const res = await client.post("/submission/", test);
      if (res.status === 201) {
        alert(t("successTestComplete"));
      }
      localStorage.removeItem("minutes");
      localStorage.removeItem("seconds");
      navigate(`/`);
      navigate(0);
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
        setButtonState(false);
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <Spacer height={"5rem"} />
        {examData !== undefined && (
          <Typography sx={{ textAlign: "center" }} variant="h4">
            {examData.name}
          </Typography>
        )}
        <Spacer height={"1rem"} />
        {time !== undefined && (
          <Timer initialMinute={time} timeFunction={() => sendTest()} />
        )}
        {questions !== undefined && (
          <form className="p-3 mt-4 border_form">
            <ol>
              {questions.map((q) => (
                <li key={q.id} value={q.value} style={{ paddingTop: "3rem" }}>
                  {q.text}
                  {q.image !== null && (
                    <Box
                      component="img"
                      sx={{
                        paddingTop: "0.8rem",
                        display: "block",
                        width: 650,
                        // maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 550 },
                      }}
                      src={q.image}
                    />
                  )}

                  <MultiChoiceAnswer
                    questionId={q.id}
                    questionText={q.choice}
                    answersArray={shuffle(q.choices)}
                    onInputChange={multiChoiceHandler}
                  ></MultiChoiceAnswer>
                </li>
              ))}{" "}
            </ol>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2rem",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={buttonState}
                onClick={() => sendTest()}
                style={{ backgroundColor: colors.green, width: "40%" }}
              >
                (t{"endTest"})
              </Button>
            </div>
          </form>
        )}
        <Spacer height={"2rem"} />
      </Container>
    </>
  );
};

export default TestPage;
