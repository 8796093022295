import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import { LocalizationContext } from "../../context/LocalizationContext";
import { colors } from "../../infrastructure/resources/colors";

import client from "../../apis/client";
import { Typography } from "@mui/material";
import CustomTextField from "../../infrastructure/components/CustomTextField";

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const { t } = React.useContext(LocalizationContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const forgotPassword = async (values) => {
    try {
      const email = values.email;
      await client.post(`/auth/users/reset_password/`, { email });
      alert(t("succesForgot"));
      navigate("/login");
      navigate(0);
    } catch (err) {
      if (err.response) {
        setError("Error!");
      }
    }
  };

  const validationSchema = yup.object({
    email: yup.string().email(t("invalidEmail")).required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: forgotPassword,
  });

  return (
    <Container component="main" maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <CustomTextField
            fullWidth
            id="email"
            name="email"
            variant="outlined"
            label={t("email")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {error !== "" && (
            <Typography
              variant="subtitle1"
              color={"error"}
              paddingTop={1}
              align="center"
            >
              {error}
            </Typography>
          )}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            style={{ backgroundColor: colors.green }}
          >
            {t("submitForgot")}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default ForgotPassword;
