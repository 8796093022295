import React, { useContext } from "react";

import { Box, Container, Typography } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import NewPasswordForm from "./forms/NewPasswordForm";
import { LocalizationContext } from "../context/LocalizationContext";
import { logo2 } from "../infrastructure/resources/images";
import "../pages/logo.css";
import { colors } from "../infrastructure/resources/colors";
import Spacer from "../infrastructure/components/Spacer";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = React.useContext(LocalizationContext);

  const redirectHome = () => {
    navigate("/");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={redirectHome}
        >
          <img src={logo2.src} alt="mihailo" className="custom-image" />
        </div>
      </div>

      <Container component="main" maxWidth="xs">
        <Box textAlign="center">
          <Typography variant="h4" sx={{ cursor: "pointer" }}>
            {t("createNewPassword")}
          </Typography>
        </Box>
        <Spacer height={"2rem"} />
        <NewPasswordForm />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <Typography
              variant="h6"
              sx={{
                color: colors.green,
                textDecoration: "underline",
              }}
              paddingTop="20%"
              onClick={redirectHome}
            >
              {t("cancel")}
            </Typography>
          </div>
        </div>
      </Container>
    </>
  );
};
export default NewPasswordPage;
