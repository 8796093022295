import React, { useState, useEffect } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Spacer from "../Spacer";
import "./MultiChoiceAnswer.css";

const MultiForOpenTest = (props) => {
  const [data] = React.useState(
    props.answersArray.map((item) => ({
      answer_id: item.id,
      answer_text: item.choice,
      checked: false,
    }))
  );
  const [selectedData] = useState("");
  const [item, setItem] = useState({});
  const { onInputChange, correctChoices, showResults } = props;

  useEffect(() => {
    onInputChange(item, selectedData);
  }, [item, selectedData, onInputChange]);

  function selectItem(key, questionId) {
    let gr = [...data];
    for (let item of gr) {
      if (item.answer_id === key) {
        item.checked = item.checked === null ? true : !item.checked;
        break;
      }
    }

    const newArray = gr.filter((g) => g.checked === true);
    let helpString = "";

    if (newArray.length === 0) {
      setItem("");
    } else {
      newArray.map((a) => {
        helpString = helpString.concat(a.answer_id).concat("$");
        setItem({
          choice_answer: { choices: helpString.slice(0, -1) },
          question: questionId,
        });
      });
    }

    const selectedChoices = newArray.map((a) => a.answer_id);
    onInputChange(questionId, selectedChoices);
}

  return (
    <>
      <Spacer height={"0.5rem"} />
      <FormGroup>
        {data.map((a) => {
          const isCorrectChoice = correctChoices.includes(a.answer_id);
          const isSelectedChoice = a.checked;

          return (
            <div key={a.answer_id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={a.checked}
                    onChange={() => selectItem(a.answer_id, props.questionId)}
                  />
                }
                label={a.answer_text}
                className={
                  showResults
                    ? isCorrectChoice
                      ? "correct-choice"
                      : isSelectedChoice
                      ? "incorrect-choice"
                      : ""
                    : ""
                }
              />
              {showResults && isCorrectChoice && (
                <span className="points">+{props.points}</span>
              )}
            </div>
          );
        })}
      </FormGroup>
    </>
  );
};

export default MultiForOpenTest;
