import React, { useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";

import { Menu } from "@mui/icons-material";

import { LocalizationContext } from "../../context/LocalizationContext";
import { colors } from "../resources/colors";
import { logo } from "../resources/images";
import LanguageDropdown from "./ui-components/LangugageDropdown";

function DrawerComponent({ links, logout }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = React.useContext(LocalizationContext);

  const renderLink = (text, link) => {
    return (
      <ListItem key={link} onClick={() => setOpenDrawer(false)}>
        <ListItemText>
          <Link
            href={`/${link}`}
            underline="none"
            sx={{
              color: colors.blue,
              fontWeight: "bold",
              textTransform: "uppercase",
              marginRight: "20px",
            }}
          >
            {t(text)}
          </Link>
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem>
            <LanguageDropdown />
          </ListItem>

          {links &&
            links.length > 0 &&
            links.map((v) => renderLink(v.text, v.link))}
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                onClick={logout}
                underline="none"
                sx={{
                  color: colors.blue,
                  cursor: "pointer",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
              >
                {t("logout")}
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component={"img"}
        src={logo.src}
        href="/"
        maxWidth={"70px"}
        sx={{ padding: 1, marginRight: "auto" }}
      />
      <div style={{ display: "flex", alignContent: "flex-end" }}>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <Menu />
        </IconButton>
      </div>
    </>
  );
}
export default DrawerComponent;
