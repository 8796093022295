import React from "react";

import { Button } from "@mui/material";
import { colors } from "../resources/colors";
import { size } from "../resources/size";

const CustomButton = ({
  text,
  color,
  background,
  variant,
  fontSize,
  fontWeight,
  value,
  width,
  handleClick,
}) => {
  return (
    <Button
      variant={variant ?? "text"}
      value={value ?? ""}
      sx={{
        color: color ?? colors.white,
        backgroundColor: background ?? colors.green,
        width: width ?? "100%",
        fontWeight: fontWeight ?? "normal",
        fontSize: fontSize ?? size.size12,
        "&:hover": {
          backgroundColor: background ?? colors.green, 
        },
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
