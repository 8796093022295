import jwt_decode from "jwt-decode";

import createDataContext from "./createDataContext";
import client_auth from "../apis/client_auth";
import client from "../apis/client";

function isTokenExpired(token) {
  var decoded = jwt_decode(token);

  if (decoded.exp < Date.now() / 1000) {
    return true;
  } else {
    return false;
  }
}

const authReducer = (state, action) => {
  switch (action.type) {
    case "add_error":
      return { ...state, errorMessage: action.payload };
    case "add_error_password":
      return { ...state, errorMessagePassword: action.payload };
    case "add_error_re_password":
      return { ...state, errorMessageRePassword: action.payload };
    case "signup":
      return { errorMessage: "", token: action.payload };
    case "add_user":
      return { errorMessage: "", user: action.payload };
    case "add_profile":
      return { ...state, errorMessage: "", user_profile: action.payload };
    case "signin":
      return {
        errorMessage: "",
        token: action.payload.access,
        refreshToken: action.payload.refresh,
      };
    case "signout":
      return { token: null, refreshToken: null, errorMessage: "" };
    case "get_user":
      return { ...state, user: action.payload };
    case "login_error":
      return { ...state, loginError: action.payload };
    case "login_values":
      return { ...state, loginValues: action.payload };
    case "login_keys":
      return { ...state, loginKeys: action.payload };
    case "signup_values":
      return { ...state, signupValues: action.payload };
    case "signup_keys":
      return { ...state, signupKeys: action.payload };
    case "submit_values":
      return { ...state, submitValues: action.payload };
    case "submit_keys":
      return { ...state, submitKeys: action.payload };
    case "profile_modal":
      return { ...state, modal: true };
    default:
      return state;
  }
};

const tryLocalSignin = (dispatch) => async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken !== null) {
      /* we have token!! 
                and we can check if refresh token is expired
            */

      if (!isTokenExpired(refreshToken)) {
        const ref = await client_auth.post("/auth/jwt/refresh/", {
          refresh: refreshToken,
        });
        const tok = JSON.stringify(ref.data);
        const parsedData = JSON.parse(tok);

        localStorage.setItem("token", parsedData.access);
        localStorage.setItem("refreshToken", parsedData.refresh);

        const r = await client.get(`/auth/users/me/`);
        dispatch({ type: "get_user", payload: r.data.email });

        // window.location.replace('/')
      } else {
        window.location.replace("/");
      }
    } else {
      // window.location.replace('/')
    }
  } catch (err) {
    logout();
  }
};

const signup =
  (dispatch) =>
  async ({ email, password, re_password, language }) => {
    try {
      const user = await client_auth.post("/auth/users/", {
        email,
        password,
        re_password,
        language,
      });
      dispatch({ type: "add_user", payload: user.data });

      window.location.replace("/");
    } catch (err) {
      if (err.response) {
        const values = Object.values(err.response.data);
        const keys = Object.keys(err.response.data);

        dispatch({ type: "signup_values", payload: values });
        dispatch({ type: "signup_keys", payload: keys });
      }
    }
  };

const signin =
  (dispatch) =>
  async ({ email, password, rememberMe }) => {
    try {
      const response = await client_auth.post("/auth/jwt/create/", {
        email,
        password,
      });
      const tok = JSON.stringify(response.data);
      const parsedData = JSON.parse(tok);

      localStorage.setItem("token", parsedData.access);
      localStorage.setItem("refreshToken", parsedData.refresh);

      dispatch({
        type: "signin",
        payload: { access: parsedData.access, refresh: parsedData.refresh },
      });
      if (rememberMe === true) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
      }

      window.location.replace("/");
    } catch (err) {
      if (err.response) {
        const values = Object.values(err.response.data);
        const keys = Object.keys(err.response.data);

        dispatch({ type: "login_values", payload: values });
        dispatch({ type: "login_keys", payload: keys });
      }
    }
  };

const signinFirst =
  (dispatch) =>
  async ({ email, password, rememberMe }) => {
    try {
      const response = await client_auth.post("/auth/jwt/create/", {
        email,
        password,
      });
      const tok = JSON.stringify(response.data);
      const parsedData = JSON.parse(tok);

      dispatch({ type: "profile_modal", payload: true });

      localStorage.setItem("token", parsedData.access);
      localStorage.setItem("refreshToken", parsedData.refresh);

      dispatch({
        type: "signin",
        payload: { access: parsedData.access, refresh: parsedData.refresh },
      });
      if (rememberMe === true) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
      }
    } catch (err) {
      if (err.response) {
        const values = Object.values(err.response.data);
        const keys = Object.keys(err.response.data);

        dispatch({ type: "login_values", payload: values });
        dispatch({ type: "login_keys", payload: keys });
      }
    }
  };

const logout = (dispatch) => async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("seconds");
  localStorage.removeItem("minutes");
  window.location.replace("/");
  dispatch({ type: "signout" });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { signup, logout, tryLocalSignin, signin, signinFirst },
  {
    token: null,
    refreshToken: null,
    errorMessage: "",
    user: null,
    user_profile: null,
    errorMessagePassword: "",
    serrorMessageRePassword: "",
    loginError: "",
    signupError: "",
    loginValues: [],
    loginKeys: [],
    signupValues: [],
    signupKeys: [],
    submitValues: [],
    submitKeys: [],
    modal: false,
  },
  []
);
