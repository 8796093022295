import React from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { LocalizationContext } from "../../context/LocalizationContext";

import client from "../../apis/client";
import CustomTextField from '../../infrastructure/components/CustomTextField';
import Spacer from '../../infrastructure/components/Spacer';
import { colors } from '../../infrastructure/resources/colors';

const initialValues = {
  current_password: "",
  new_password: "",
  re_new_password: "",
};

const ChangePassword = ({onClose}) => {
  const { t } = React.useContext(LocalizationContext);
  const [error, setError] = React.useState("");

  const changePassword = async (values) => {
    try {
      const current_password = values.current_password;
      const new_password = values.new_password;
      const re_new_password = values.re_new_password;
      if (new_password === re_new_password) {
        const res = await client.post(`/auth/users/set_password/`, {
          new_password,
          current_password,
        });
        if (res.status === 204) {
          alert(t("successChangePass"));
          onClose()
        }
      }
    } catch (error) {
      const values = Object.values(error.response.data);
      const keys = Object.keys(error.response.data);

      if (keys !== undefined && values !== undefined) {
        if (keys.length !== 0 && values.length !== 0) {
          if (
            values[0][0] === "The password is too similar to the email." &&
            keys[0] === "new_password"
          ) {
            setError(t("passwordSimilarEmail"));
            return;
          } else if (
            values[0][0] === "This password is too common." &&
            keys[0] === "new_password"
          ) {
            setError(t("tooCommon"));
            return;
          } else if (
            values[0][0] === "Invalid password." &&
            keys[0] === "current_password"
          ) {
            setError(t("invalidCurrentPassword"));
            return;
          } else {
            setError(t("checkData"));
            return;
          }
        }
      }
    }
  };

  const validationSchema = yup.object({
    current_password: yup.string().required(t("required")),
    new_password: yup
      .string()
      .required(t("required"))
      .min(8, t("min8"))
      .max(40, t("max40")),
    re_new_password: yup
      .string()
      .required(t("required"))
      .oneOf([yup.ref("new_password"), null], t("matchPassword")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: changePassword,
  });

  return (
    <Container component="main" maxWidth="sm">
      <div xs={12} sm={6} lg={8}>
        <form onSubmit={formik.handleSubmit} className="mt-3 m-2">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="current_password"
                label={t("currentPassword")}
                name="current_password"
                variant="outlined"
                type="password"
                value={formik.values.current_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.current_password &&
                  Boolean(formik.errors.current_password)
                }
                helperText={
                  formik.touched.current_password &&
                  formik.errors.current_password
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="new_password"
                label={t("newPassword")}
                name="new_password"
                variant="outlined"
                type="password"
                value={formik.values.new_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fullWidth
                id="re_new_password"
                label={t("reNewPassword")}
                name="re_new_password"
                variant="outlined"
                type="password"
                value={formik.values.re_new_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.re_new_password &&
                  Boolean(formik.errors.re_new_password)
                }
                helperText={
                  formik.touched.re_new_password &&
                  formik.errors.re_new_password
                }
              />
            </Grid>

            {error !== "" && (
              <Grid item xs={12}>
                <p style={{ color: "red" }}>{error}</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <Spacer height="1rem" />
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{ backgroundColor: colors.green }}
              >
                {t("submitChangePassword")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ChangePassword;
