import React from "react";

import TextField from "@mui/material/TextField";

const styles = {
  root: {
    "& label.Mui-focused": {
      color: "#52C0EE",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#52C0EE",
      },
    },
  },
};

const CustomTextField = (props) => {
  return (
    <TextField
      fullWidth={props.fullWidth}
      accept={props.accept}
      variant={props.variant}
      select={props.select}
      as={props.as}
      spacing={props.spacing}
      sx={styles.root}
      style={props.style}
      multiline={props.multiline}
      minRows={props.minRows}
      label={props.label}
      id={props.id}
      format={props.format}
      type={props.type}
      name={props.name}
      value={props.value}
      defaultValue={props.defaultValue}
      InputLabelProps={props.InputLabelProps}
      InputProps={props.InputProps}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
    >
      {props.children}
    </TextField>
  );
};
export default CustomTextField;
