import React from "react";
import {
  Card,
  Typography,
  CardActions,
  CardContent,
  Grid,
  Box,
  Avatar,
  CardMedia,
} from "@mui/material";
import { LocalizationContext } from "../../context/LocalizationContext";
import { colors } from "../resources/colors";
import { useNavigate } from "react-router-dom";
import Spacer from "./Spacer";
import CustomCard from "./CustomCard";

const LabelsCard = ({ labels }) => {
  const { t } = React.useContext(LocalizationContext);
  const navigate = useNavigate();

  const navigateToLibrary = async (id) => {
    try {
      navigate(`/videoteka/${id}`);
      navigate(0);
    } catch (err) {}
  };

  return (
    <Card sx={{ ...styles.styles, overflowY: "auto" }}>
      <CardActions>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("videoLibrary")}
        </Typography>
      </CardActions>
      <CardContent>
        {labels && labels.length > 0 && (
          <Grid container spacing={2} alignItems={"center"}>
            {labels.map((v, idx) => (
              <Grid item key={idx} xs={12} sm={4} sx={styles.labelCard}>
                <Card onClick={() => navigateToLibrary(v.id)} sx={{cursor: "pointer"}}>
                  <Avatar
                    variant="square"
                    sx={{ height: 345, width: "auto" }}
                    src={v.image}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={styles.labelText}
                    >
                      {v.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        {labels === undefined && (
          <Typography variant="body1">{t("noMoreVideo")}</Typography>
        )}{" "}
      </CardContent>
    </Card>
  );
};

const styles = {
  labelCard: {
    margin: 0,
    alignItems: "center",
  },
  labelText: {
    textAlign: "center",
    fontStyle: "italic",
    color: colors.blue,
  },
  styles: {
    borderBottom: `1px solid ${colors.green}`,
    boxShadow: `0 0 10px rgba(${colors.greenRgba}, 0.6)`,
  },
};
export default LabelsCard;
