const cyr = {
  home: "Почетна",
  profile: "Профил",
  education: "Едукација",
  tests: "Тестови",
  videoLibrary: "Видеотека",
  logout: "Одјави се",
  login: "Пријави се",
  register: "Региструј се",
  email: "Имејл",
  password: "Лозинка",
  rememberMe: "Запамти ме",
  submitLogin: "Пријави се",
  submitRegister: "Региструј се",
  submitForgot: "Потврди",
  submitResetPassword: "Потврди",
  submitProfile: "Потврди",
  submitChangePassword: "Потврди",
  submitDeleteAccount: "Потврди",
  required: "Обавезно поље",
  invalidEmail: "Неисправан имејл",
  tooCommon: "Ова лозинка је уобичајена",
  alreadyExist: "Кориснички налог са овом имејл адресом већ постоји",
  passwordSimilarEmail: "Лозинка не сме бити слична имејлу",
  checkData: "Проверите да ли сте добро унели податке",
  min8: "Лозинка мора садржати најмање 8 карактера",
  max40: "Лозинка не сме прећи више од 40 карактера",
  noActiveAccount: "Не постоји налог са овим креденцијалима",
  forgotPassword: "Заборављена лозинка",
  dontHaveAccount: "Ако још немате налог ",
  registerPage: "идите на страницу за регистрацију.",
  succesForgot:
    "Послали смо Вам имејл са линком за промену лозинке! Ако не примите имејл у наредних пар минута, проверите у порукама промоције или спам фолдеру.",
  goToStart: "Врати се на почетну страну",
  name: "Име и презиме",
  rePassword: "Понови лозинку",
  language: "Језик",
  sr: "Српски",
  en: "Енглески",
  byRegister: "Регистрацијом прихватам ",
  termsAndCondition: "Услове и Правила коришћења.",
  termsAndConditionModal: "Услови и Правила коришћења",
  haveAccount: "Ако имате налог ",
  loginPage: "идите на страницу за пријављивање.",
  currentPassword: "Тренутна лозинка",
  newPassword: "Нова лозинка",
  reNewPassword: "Понови нову лозинку",
  matchPassword: "Лозинке се не подударају",
  successChangePass: "Успешно сте променили лозинку.",
  male: "Мушки",
  female: "Женски",
  postProfile: "Креирај профил",
  other: "Друго",
  dateOfBirth: "Датум рођења",
  gender: "Пол",
  city: "Град",
  succesProfileCreate: "Успешно сте креирали профил!",
  forgotPasswordForm: "Заборавили сте лозинку?",
  createNewPassword: "Направи нову лозинку",
  enterValidEmail: "Унесите валидну имејл адресу",
  passwordShort: "Ова лозинка је кратка",
  tooShort: "Превише кратка",
  allFields: "Сва поља",
  minChar: "Минимум 8 слова",
  maxChar: "Максимум 40 слова",
  submit: "Потврди",
  tooSimilar: "Лозинка је превише слична имејлу",
  enterEmail: "Унесите ваш имејл и послаћемо вам линк за ресетовање лозинке.",
  succesProfileEdit: "Успешно сте изменили профил!",
  cancel: "Откажи",
  comingSoon: "Долази ускоро",
  requiredEngagement: "Обавезно додати ангажовање",
  currentLeague: "Тренутно ангажовање",
  startTest: "Започни писмени тест",
  startVideoTest: "Започни видео тест",
  editProfile: "Уреди профил",
  changePassword: "Промени лозинку",
  deleteAccount: "Обриши налог",
  invalidCurrentPassword: "Погрешна тренутна лозинка",
  successDeleteAccount: "Успешно сте обрисали налог.",
  noTest: "Нажалост, тренутно немате ни један тест.",
  notFound: "Тражена страница није пронађена.",
  succesRegister: "Успешно сте се регистровали",
  error: "Грешка",
  profileEdit: "Измени профил",
  profileCreate: "Креирај профил",
  successTestComplete: "Успешно сте завршили тест",
  downloadFile: "Преузми датотеку",
  noMoreVideo: "Нема још видео лекција",
  endTest: "Заврши тест",
  invalidPassword: "Погрешна лозинка",
  checkDate: "Проверите унете податке.",
  smallDate: "Крај ангажовања не може бити мањи од почетка ангажовања",
  serverError: "Серверска грешка",
  loginMessage: "Већ имате налог?",
  dontHaveAcc: "Немате налог?",
  goRegister: "Региструјте се.",
  goLogin: "Пријавите се.",
  noVideosYet: "Још нема видеа у овој области",
  chooseVideo: "Изаберите видео",
  seeMore: "Погледајте видео и на",
  nextLink: "следећем линку.",
  label: "Тип курса",
  submitFilter: "Потврди",
  resetFilter: "Обриши",
  sumPoints: "Остварено поена",
};

export default cyr;
