import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";

import LanguageDropdown from "./ui-components/LangugageDropdown";
import { LocalizationContext } from "../../context/LocalizationContext";
import { logo } from "../resources/images";
import Spacer from "./Spacer";
import { size } from "../resources/size";
import { colors } from "../resources/colors";
import DrawerComponent from "./DrawerComponent";
import { createTheme, useMediaQuery } from "@mui/material";

const logoutHover = {
  color: colors.blue,
  "&:hover": {
    color: "inherit",
  },
};

const Navigator = (props) => {
  const { t } = React.useContext(LocalizationContext);
  const navigate = useNavigate();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const logout = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('seconds')
    localStorage.removeItem('minutes')
    navigate("/");
    navigate(0);
  };

  const links = [
    { text: "home", link: "" },
    { text: "videoLibrary", link: "videoteka" },
    // { text: "tests", link: "testovi" },
  ];

  const renderLink = (text, link) => {
    return (
      <Link
        key={link}
        href={`/${link}`}
        underline="none"
        sx={{
          color: colors.blue,
          fontWeight: "bold",
          textTransform: "uppercase",
          marginRight: "20px",
        }}
      >
        {t(text)}
      </Link>
    );
  };

  return (
    <Box>
      <AppBar>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: colors.white,
          }}
        >
          {isMobile ? (
            <DrawerComponent links={links} logout={() => logout()} />
          ) : (
            <React.Fragment>
              <Typography
                variant="h6"
                noWrap
                sx={{ mr: 2, display: "flex" }}
              >
                <Link href="/" color="inherit" underline="none">
                  <img
                    src={logo.src}
                    alt="mihailo"
                    className="custom-image2"
                  ></img>
                </Link>
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: "flex" }}
              >
                <div style={{ display: "flex" }}>
                  <Spacer />
                  {links &&
                    links.length > 0 &&
                    links.map((v) => renderLink(v.text, v.link))}
                </div>
              </Typography>
              <Box
                sx={{
                  flexGrow: 0,
                  "&:hover": {
                    cursor: "pointer",
                    color: "#000045",
                  },
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    hover: {
                      "&:hover": {
                        background: "none",
                      },
                    },
                  }}
                >
                  <LanguageDropdown fontSize={size.size12} />
                  <Link
                    onClick={() => logout()}
                    color={logoutHover}
                    underline="none"
                    display={"flex"}
                    justifyContent={"center"}
                    paddingTop={"10px"}
                  >
                    {t("logout")}
                  </Link>
                </div>
              </Box>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Spacer height={'7rem'} />
    </Box>
  );
};

export default Navigator;
