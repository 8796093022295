import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { LocalizationContext } from "../../context/LocalizationContext";

import client from "../../apis/client";
import { Typography } from "@mui/material";
import { colors } from "../../infrastructure/resources/colors";
import CustomTextField from "../../infrastructure/components/CustomTextField";

const ProfileForm = (props) => {
  const { t } = React.useContext(LocalizationContext);
  let navigate = useNavigate();
  const [buttonState, setButtonState] = useState(false);
  const [profileData, setProfileData] = useState(
    props.profileData || undefined
  );

  const initialValues = {
    date_of_birth:
      profileData !== undefined
        ? profileData.date_of_birth
        : moment(new Date()).format("YYYY-MM-DD"),
    name: profileData !== undefined ? profileData.name : "",
  };

  const postProfile = async (values) => {
    try {
      setButtonState(true);
      const user = await client.get("/auth/users/me/");
      const owner = await user.data.id;

      let profile = {
        date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
        name: values.name,
        user: owner,
      };

      if (profileData !== undefined) {
        await client.put(`/profiles/${profileData.id}/`, profile);

        alert(t("succesProfileEdit"));
        props.onClose();
      } else {
        await client.post("/profiles/", profile);

        alert(t("succesProfileCreate"));

        navigate("/");
        navigate(0);
      }
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data));
        setButtonState(false);
      }
    }
  };

  const validationSchema = yup.object({
    date_of_birth: yup.date().required(t("required")),
    name: yup.string().required(t("required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: postProfile,
  });

  return (
    <Container component="main" style={{ paddingBottom: "10px" }}>
      <Typography m={3} variant="h4" display={"flex"} justifyContent={"center"}>
        {profileData !== undefined ? t("editProfile") : t("postProfile")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              label={t("name")}
              InputLabelProps={{ required: true }}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              id="date_of_birth"
              name="date_of_birth"
              type="date"
              variant="outlined"
              label={t("dateOfBirth")}
              format="yyyy/MM/dd"
              defaultValue={formik.values.date_of_birth}
              selected={formik.values.date_of_birth}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true, required: true }}
              error={
                formik.touched.date_of_birth &&
                Boolean(formik.errors.date_of_birth)
              }
              helperText={
                formik.touched.date_of_birth && formik.errors.date_of_birth
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={buttonState}
              style={{ backgroundColor: colors.green }}
            >
              {profileData !== undefined
                ? t("profileEdit")
                : t("profileCreate")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ProfileForm;
