import React from "react";
import { Card, Typography, CardHeader, Avatar } from "@mui/material";
import { colors } from "../resources/colors";

const CourseCard = ({ course }) => {
  return (
    course && (
      <Card sx={{ ...styles.styles }}>
        <CardHeader
          title={course.name}
          subheader={
            course.labels &&
            course.labels.map((label, idx) => (
              <Typography key={idx}>{label.name}</Typography>
            ))
          }
          style={{ marginLeft: "25px" }}
        />
        <Avatar
          alt={course.name}
          sx={{
            width: 200,
            height: 200,
            marginRight: "25px",
            marginBottom: "10px",
          }}
          src={course.image}
        />
      </Card>
    )
  );
};

const styles = {
  labelCard: {
    margin: 0,
  },
  labelText: {
    textAlign: "center",
    fontStyle: "italic",
    color: colors.blue,
    cursor: "pointer",
  },
  styles: {
    display: "flex",
    border: "none",
    boxShadow: "none",
  },
};
export default CourseCard;
