import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ModalMui from "@mui/material/Modal";
import { LocalizationContext } from "../context/LocalizationContext";

import client from "../apis/client";
import Spacer from "../infrastructure/components/Spacer";
import ProfileForm from "./forms/ProfileForm";
import CustomModal from "../infrastructure/components/CustomModal";
import ProfileCard from "../infrastructure/components/ProfileCard";
import Spinner from "../infrastructure/components/Spinner";
import { colors } from "../infrastructure/resources/colors";
import ChangePassword from "./forms/ChangePassword";
import DeleteAccount from "./forms/DeleteAccount";
import LabelsCard from "../infrastructure/components/LabelsCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: `2px solid ${colors.green}`,
  boxShadow: 24,
  p: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_modal":
      return { ...state, modal: action.payload };
    case "set_profile_data":
      return { ...state, profileData: action.payload };
    case "set_assesment":
      return { ...state, assesment: action.payload };
    case "set_education_labels":
      return { ...state, educationLabels: action.payload };
    case "set_is_loading":
      return { ...state, isLoading: action.payload };
    case "set_disabled":
      return { ...state, disabled: action.payload };
    case "set_modal_type":
      return { ...state, modalType: action.payload };
    case "set_reload":
      return { ...state, reload: action.payload };
    default:
      return state;
  }
};

const HomePage = (props) => {
  const [state, dispatch] = React.useReducer(reducer, {
    profileData: undefined,
    assesment: undefined,
    educationLabels: undefined,
    isLoading: false,
    disabled: false,
    modal: false,
    reload: false,
    modalType: "",
  });
  const { t } = React.useContext(LocalizationContext);

  const handleShowModal = (type) => {
    dispatch({ type: "set_modal", payload: true });
    dispatch({ type: "set_modal_type", payload: type });
  };

  const handleCloseModal = () => {
    dispatch({ type: "set_modal", payload: false });
    dispatch({ type: "set_modal_type", payload: "" });
  };

  const navigate = useNavigate();

  useEffect(() => {
    let isActive = true;
    const loadData = async () => {
      try {
        const profile = await client.get(`/profiles/`);
        const user = await client.get(`/auth/users/me/`);
        const as = await client.get(`/assessment/`);
        const lab = await client.get(`/education/courses/`);

        if (isActive) {
          if (profile.data.length === 0) {
            handleShowModal("postProfile");
          } else {
            dispatch({
              type: "set_profile_data",
              payload: await profile.data[0],
            });
            dispatch({ type: "set_assesment", payload: await as.data.length });
            dispatch({ type: "set_education_labels", payload: await lab.data });
            handleCloseModal();

            if (
              localStorage.getItem("minutes") === null &&
              localStorage.getItem("seconds") !== null
            ) {
              localStorage.removeItem("seconds");
            }
            if (as.data.length === 0) {
              localStorage.removeItem("minutes");
              localStorage.removeItem("seconds");
            }
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    };

    if (isActive) loadData();

    return () => {
      isActive = false;
    };
  }, [state.reload]);

  const getTest = () => {
    try {
      if (state.assesment !== 0) {
        navigate("/test", { state: { profile: state.profileData.id } });
        navigate(0);
      } else {
        navigate("/nema_testa");
        navigate(0);
      }
    } catch (error) {}
  };

  return (
    <Container component="main" maxWidth="lg">
      <Spacer height={"3rem"} />
      {state.isLoading && <Spinner />}
      {!state.isLoading && state.profileData && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileCard
              profileData={state?.profileData}
              onEditProfile={() => handleShowModal("editProfile")}
              onChangePassword={() => handleShowModal("changePassword")}
              onDeleteProfile={() => handleShowModal("deleteAccount")}
              haveAssesment={state?.assesment}
              getTest={getTest}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelsCard labels={state.educationLabels} />
          </Grid>
        </Grid>
      )}
      <Modal
        open={state.modal && state.modalType === "postProfile"}
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <ProfileForm />
        </Box>
      </Modal>
      <CustomModal
        open={
          state.modal &&
          state.modalType !== "postProfile" &&
          state.modalType !== "editProfile"
        }
        onClose={handleCloseModal}
        text={t(state.modalType)}
      >
        {state.modalType === "deleteAccount" && <DeleteAccount />}
        {state.modalType === "changePassword" && (
          <ChangePassword
            onClose={() => {
              handleCloseModal();
            }}
          />
        )}
      </CustomModal>

      <ModalMui
        open={state.modal === true && state.modalType === "editProfile"}
        onClose={handleCloseModal}
        style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <ProfileForm
            profileData={state.profileData}
            onClose={() => {
              dispatch({ type: "set_reload", payload: !state.reload });
              handleCloseModal();
            }}
          />
        </Box>
      </ModalMui>
    </Container>
  );
};

export default HomePage;
