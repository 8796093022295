import React from "react";

import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomPasswordField from "./CustomPasswordField";

import { Typography } from "@mui/material";
import { colors } from "../../infrastructure/resources/colors";
import { LocalizationContext } from "../../context/LocalizationContext";
import CustomTextField from "../../infrastructure/components/CustomTextField";

const CustomForm = (props) => {
  const { t } = React.useContext(LocalizationContext);
  const onFormSubmit = async (values) => {
    try {
      props.onFormSubmit(values);
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: props.initialValues,
    validationSchema: props?.validationSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        {props.formFields.length !== 0 &&
          props.formFields.map((f, ind) => {
            return (
              <Grid key={ind} item xs={12}>
                {f.type === "password" && (
                  <CustomPasswordField
                    name={f.name}
                    label={f.label}
                    formik={formik}
                  />
                )}
                {f.type === "title" && (
                  <Typography variant="h2">{f.text}</Typography>
                )}
                {f.type === "text" && (
                  <CustomTextField
                    name={f.name}
                    label={f.label}
                    placeholder={f.placeholder}
                    formik={formik}
                  />
                )}
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={props.disabled}
            fullWidth
            type="submit"
            style={{ backgroundColor: colors.green }}
          >
            {props.text ?? t("submit")}
          </Button>
          {props?.cancelFunction && (
            <Button
              label={"cancel"}
              onPress={props?.cancelFunction}
              backgroundColor={"transparent"}
              textColor={"gray"}
              textDecoration={"underline"}
              fontWeight="normal"
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomForm;
