import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardActions,
  ListItem,
  ListItemIcon,
  CardContent,
} from "@mui/material";
import { LocalizationContext } from "../../context/LocalizationContext";
import moment from "moment";
import { Delete, Edit, Lock } from "@mui/icons-material";
import { colors } from "../resources/colors";
import CustomButton from "./CustomButton"

const ProfileCard = ({
  profileData,
  onEditProfile,
  onDeleteProfile,
  onChangePassword,
  haveAssesment,
  getTest,
}) => {
  const { name, date_of_birth } = profileData;
  const { t } = React.useContext(LocalizationContext);

  return (
    <Card sx={{ ...styles.styles, overflowY: "auto" }}>
      <CardActions>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("profile")}
        </Typography>
        <ListItem sx={styles.flexEnd}>
          <ListItemIcon>
            <Edit onClick={onEditProfile} sx={{ cursor: "pointer" }} />
          </ListItemIcon>
          <ListItemIcon>
            <Lock onClick={onChangePassword} sx={{ cursor: "pointer" }} />
          </ListItemIcon>
          <ListItemIcon>
            <Delete onClick={onDeleteProfile} sx={{ cursor: "pointer" }} />
          </ListItemIcon>
        </ListItem>
      </CardActions>
      <CardHeader
        title={<Typography variant="h5">{name}</Typography>}
        subheader={
          <Typography variant="body1">
            {moment(date_of_birth).format("DD. MM. YYYY.")}
          </Typography>
        }
      />
      {haveAssesment !== undefined && haveAssesment !== 0 && (
        <CardContent sx={{display: 'flex', justifyContent: "flex-end"}}>
          <CustomButton text={t("startTest")} width="fit-content" handleClick={getTest} />
        </CardContent>
      )}
    </Card>
  );
};

const styles = {
  flexEnd: {
    justifyContent: "flex-end",
    display: "flex",
    marginRight: "0px",
    marginLeft: "auto",
  },
  styles: {
    borderBottom: `1px solid ${colors.green}`,
    boxShadow: `0 0 10px rgba(${colors.greenRgba}, 0.6)`
  },
};
export default ProfileCard;
