const lat = {
  home: "Početna",
  profile: "Profil",
  education: "Edukacija",

  tests: "Testovi",
  videoLibrary: "Videoteka",
  logout: "Odjavi se",
  login: "Prijavi se",
  register: "Registruj se",
  email: "Imejl",
  password: "Lozinka",
  rememberMe: "Zapamti me",
  submitLogin: "Prijavi se",
  submitRegister: "Registruj se",
  submitForgot: "Potvrdi",
  submit: "Potvrdi",
  submitResetPassword: "Potvrdi",
  submitProfile: "Potvrdi",
  submitChangePassword: "Potvrdi",
  submitDeleteAccount: "Potvrdi",
  required: "Obavezno polje",
  downloadFile: "Preuzmi datoteku",
  invalidEmail: "Neispravan imejl",
  tooCommon: "Ova lozinka je uobičajena",
  alreadyExist: "Korisnički nalog sa ovom imejl adresom već postoji",
  passwordSimilarEmail: "Lozinka ne sme biti slična imejlu",
  checkData: "Proverite da li ste dobro uneli podatke",
  min8: "Lozinka mora sadržati minimum 8 karaktera",
  max40: "Lozinka ne sme preći više od 40 karaktera",
  noActiveAccount: "Ne postoji nalog sa ovim kredencijalima",
  forgotPassword: "Zaboravljena lozinka",
  dontHaveAccount: "Ako još nemate nalog ",
  noMoreVideo: "Nema još video lekcija",
  registerPage: "idite na stranicu za registraciju.",
  succesForgot:
    "Poslali smo Vam imejl sa linkom za promenu lozinke! Ako ne primite imejl u narednih par minuta, proverite u porukama promocije ili spam folderu.",
  goToStart: "Vrati se na početnu stranu",
  name: "Ime i prezime",
  rePassword: "Ponovi lozinku",
  language: "Jezik",
  sr: "Srpski",
  en: "Engleski",
  byRegister: "Registracijom prihvatam ",
  comingSoon: "Dolazi uskoro",
  termsAndCondition: "Uslove i Pravila korišćenja.",
  termsAndConditionModal: "Uslovi i Pravila korišćenja",
  haveAccount: "Ako imate nalog ",
  loginPage: "idite na stranicu za prijavljivanje.",
  currentPassword: "Trenutna lozinka",
  newPassword: "Nova lozinka",
  reNewPassword: "Ponovi novu lozinku",
  minChar: "Minimum 8 slova",
  maxChar: "Maksimum 40 slova",
  matchPassword: "Lozinke se ne podudaraju",
  successChangePass: "Uspešno ste promenili lozinku.",
  successTestComplete: "Uspešno ste završili test",
  endTest: "Završi test",
  createAvatar: "Dodaj sliku",
  male: "Muški",
  female: "Ženski",
  other: "Drugo",
  dateOfBirth: "Datum rođenja",
  gender: "Pol",
  city: "Grad",
  succesProfileCreate: "Uspešno ste kreirali profil!",
  succesRegister: "Uspešno ste se registrovali",
  profileCreate: "Kreiraj profil",
  profileEdit: "Izmeni profil",
  succesProfileEdit: "Uspešno ste izmenili profil!",
  startTest: "Započni pismeni test",
  startVideoTest: "Započni video test",
  editProfile: "Uredi profil",
  changePassword: "Izmeni lozinku",
  deleteAccount: "Obriši nalog",
  invalidCurrentPassword: "Pogrešna trenutna lozinka",
  invalidPassword: "Pogrešna lozinka",
  successDeleteAccount: "Uspešno ste obrisali nalog.",
  noTest: "Nažalost, trenutno nemate ni jedan test.",
  enterValidEmail: "Unesite validnu imejl adresu",
  
  notFound: "Tražena stranica nije pronađena.",
  noMoreEngMan: "Ne možete biti trenutno angažovani u više od jedne lige",
  checkDate: "Proverite unete podatke.",
  serverError: "Serverska greška",
  error: "Greška",
  registerMessage: "Nemate nalog?",
  loginMessage: "Već imate nalog?",
  dontHaveAcc: "Nemate nalog?",
  goRegister: "Registrujte se.",
  goLogin: "Prijavite se.",
  passwordShort: "Ova lozinka je kratka",
  forgotPasswordForm: "Zaboravili ste lozinku?",
  enterEmail: "Unesite vaš imejl i poslaćemo vam link za resetovanje lozinke.",
  noVideosYet: "Još nema videa u ovoj oblasti",
  chooseVideo: "Izaberite video",
  createNewPassword: "Napravi novu lozinku",
  tooShort: "Previše kratka",
  allFields: "Sva polja",
  tooSimilar: "Lozinka je previše slična imejlu.",
  cancel: "Otkaži",
  seeMore: "Pogledajte video i na",
  nextLink: "sledećem linku.",
  label: "Tip kursa",
  submitFilter: "Potvrdi",
  resetFilter: "Obriši",
  sumPoints: "Ostvareno poena",
};

export default lat;
