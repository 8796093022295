import React from 'react'
import { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';

const Timer = (props) => {
    const {initialMinute = 0, initialSeconds = 0} = props;
    const [minutes, setMinutes] = useState(localStorage.getItem('minutes') ?? initialMinute);
    const [seconds, setSeconds] =  useState(localStorage.getItem('seconds') ?? initialSeconds);

    useEffect(()=>{
        let myInterval
        myInterval = setInterval(() => {
        
            if (seconds > 0) {
                setSeconds(seconds - 1);
                localStorage.setItem('seconds', seconds)
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    localStorage.removeItem('seconds')
                    localStorage.removeItem('minutes')
                    props.timeFunction()
                 
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                    localStorage.setItem('minutes', minutes -1)
                    localStorage.setItem('seconds', seconds)
                }
            } 
        }, 1000, )
        return ()=> {
            clearInterval(myInterval);
          };
    },[minutes, seconds]);

    return (
        <>
        { minutes === 0 && seconds === 0
            ? null
            : <Typography style={{textAlign: 'end'}} variant='h6'> {minutes}:{seconds< 10 ?  `0${seconds} minuta` : `${seconds} minuta`}</Typography> 
        }
        </>
    )
}

export default Timer;